//@author: devin

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewEncapsulation,
    ViewRef,
} from "@angular/core";
import { S25DropdownAbstract } from "../s25.dropdown.abstract";
import { SearchCriteriaType } from "../../../pojo/SearchCriteriaI";
import { SearchCriteriaService } from "../../../services/search/search-criteria/search.criteria.service";
import { DropdownDataUtil } from "../dropdown.data.util";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { SearchCriteriaContext } from "../../../services/search/search-criteria/search.criteria.context";
import { DropDownItem } from "../../../pojo/DropDownItem";
import { jSith } from "../../../util/jquery-replacement";

@TypeManagerDecorator("s25-ng-dropdown-search-criteria")
@Component({
    selector: "s25-ng-dropdown-search-criteria",
    template: `
        @if (this.items) {
            <s25-generic-dropdown
                [(chosen)]="this.chosen"
                [hasFav]="this.hasFav"
                [onSelect]="this.onSelect"
                [items]="this.items"
                [staticItems]="this.staticItems"
                [autoOpen]="this.autoOpen"
                [alwaysOpen]="this.alwaysOpen"
                [hideSearch]="this.hideSearch"
                [focusFirst]="false"
                [searchEnabled]="this.searchEnabled"
                [apiBean]="this.apiBean"
                [serverSide]="this.serverSide"
                [placeholder]="this.placeholder || defaultPlaceholder"
                [resetSelectedOnCleanup]="this.resetSelectedOnCleanup"
                [popoverClass]="this.popoverClass"
                [useChoiceForChosen]="useChoiceForChosen"
                [onLoad]="onLoad"
                [noSelection]="noSelectionUsePlaceholder ? placeholder || defaultPlaceholder : noSelection"
                [filter]="filter"
                [emptyText]="emptyText"
                [renderItem]="type !== 'eventRequirements'"
                [hasNoneItem]="hasNoneItem"
                [noneItemLabel]="noneItemLabel"
                (chosenChange)="onChosenChange($event)"
            >
            </s25-generic-dropdown>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25DropdownSearchCriteriaComponent extends S25DropdownAbstract implements OnInit, OnChanges {
    @Input() type: SearchCriteriaType["type"];
    @Input() popoverClass?: string;
    @Input() placeholder: string;
    @Input() useChoiceForChosen: boolean;
    @Input() noSelectionUsePlaceholder: boolean;

    defaultPlaceholder: string; // Used if no placeholder is provided

    static MIN_ITEMS_FOR_SEARCH: number = 10;

    constructor(
        private zone: NgZone,
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.type && !changes.type.isFirstChange()) {
            this.staticItems = [];
            this.ngOnInit();
        }
    }

    ngOnInit() {
        let context = SearchCriteriaContext.Context[this.type];
        if (context) {
            this.defaultPlaceholder = "Select from " + context.title;
            this.hasFav = S25Util.toBool(context.showFavorite);

            if (this.chosen && !this.chosen.itemTypeId) {
                this.chosen.itemTypeId = context.itemTypeId;
            }

            let p: Promise<any>;
            let DDItems: DropDownItem[] = [];

            if (context.expensiveSearch) {
                this.searchEnabled = true;
                p = context
                    .serviceMethod(
                        //fetch favorites
                        -999, //cacheId
                        0, //pageNum
                        999, //pageSize
                        null, //filter
                        true, //filterFavorites
                        null, //domainFilter
                        this.customFilterValue, //permanent customFilterValue
                    )
                    .then((data: any) => {
                        this.zone.run(() => {
                            this.staticItems = this.staticItems || (this.chosen ? [this.chosen] : []);
                            let staticCopy = [].concat(this.staticItems);
                            let favorites = SearchCriteriaService.extractItems(data);

                            jSith.forEach(favorites, (favKey, fav) => {
                                let inStatic = false;

                                jSith.forEach(
                                    staticCopy,
                                    (itemKey, item) => {
                                        if (parseInt(fav.itemId) === parseInt(item.itemId)) {
                                            inStatic = true;
                                            Object.assign(item, { ...fav, ...item });
                                            return true;
                                        }
                                    },
                                    false,
                                    true,
                                );

                                if (!inStatic) {
                                    if (staticCopy.length && staticCopy[0].grp) {
                                        fav.grp = "Starred";
                                    }
                                    this.staticItems.push(fav);
                                }
                            });

                            this.serverSide = DropdownDataUtil.getServerSideModel(
                                context.serviceMethod,
                                this.elementRef.nativeElement,
                                this.customFilterValue,
                            );
                            DDItems = S25Util.deepCopy(this.staticItems);
                        });
                    });
            } else {
                //if useSecurity is set, list of event types is filtered to those allowed by rose config and preference config (used for writing new event type)
                p = context
                    .serviceMethod(
                        S25Util.toBool(this.useSecurity),
                        S25Util.toBool(this.activeOnly),
                        S25Util.toBool(this.omitReadOnly),
                        this.customFilterValue,
                    )
                    .then((data: DropDownItem[]) => {
                        this.zone.run(() => {
                            let statics = S25Util.deepCopy(this.staticItems);
                            let items = SearchCriteriaService.extractItems(data);
                            this.staticItems = items.filter((obj: DropDownItem) => {
                                return obj.favorite === "T";
                            });
                            statics && statics.length > 0 ? this.staticItems.unshift(statics[0]) : "";
                            DDItems = items;
                            this.searchEnabled =
                                DDItems.length > S25DropdownSearchCriteriaComponent.MIN_ITEMS_FOR_SEARCH;
                        });
                    });
            }

            p.then(() => {
                this.items = DDItems;
                this.cd && !(this.cd as ViewRef).destroyed && this.cd.detectChanges();
            });
        }
    }
}
