import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewEncapsulation,
} from "@angular/core";
import { S25DropdownAbstract } from "../s25.dropdown.abstract";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { BpeVarsConst } from "../../bpe/s25.bpe.const";
import { Doc } from "../../system-settings/document-management/s25.document.const";

@TypeManagerDecorator("s25-bpe-vars-dropdown")
@Component({
    selector: "s25-bpe-vars-dropdown",
    template: `
        @if (this.items) {
            <s25-generic-dropdown
                [items]="this.items"
                [(chosen)]="this.chosen"
                [onSelect]="this.onSelect"
                (chosenChange)="onChosenChange($event)"
                [searchEnabled]="true"
                [placeholder]="placeholder"
            ></s25-generic-dropdown>
        }
    `,
    styles: ``,
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25BpeVarsDropdownComponent extends S25DropdownAbstract implements OnInit, OnChanges {
    @Input() type?: keyof typeof BpeVarsConst.Items;
    @Input() placeholder: string;
    @Input() scope: Doc.ScopeId;

    constructor(private changeDetector: ChangeDetectorRef) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.type || changes.scope) {
            this.setItems();
        }
    }

    ngOnInit() {
        this.setItems();
    }

    setItems() {
        this.items = BpeVarsConst.Items[this.type] || BpeVarsConst.Items.event;
        if (this.scope) {
            this.items = this.items.filter((item) => !item.scopes || item.scopes.includes(this.scope));
        }
        this.changeDetector.detectChanges();
    }
}

export type VariableWithOptions = {
    val?: string;
    txt?: string;
    options?: {
        type: VariableOptionType;
        id: string;
        label?: string;
        substitute?: string;
        prop?: string;
        hidden?: boolean;
        data?: any;
        scopes?: Doc.ScopeId[];
        options?: VariableWithOptions[];
    }[];
    hasFormat?: boolean;
    vars?: { var: string; options: Record<string, boolean> }[];
    noPre?: boolean;
};

export type VariableOptionType = "checkbox" | "toggle" | "dropdown" | "roleDropdown" | "custom";
